import { FaCheckCircle, FaMicrochip } from "react-icons/fa";
import { skills } from "../info/skill";

const Skillz = () => {
  return (
    <section id="skills" className="bg-white">
      <div className="px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <FaMicrochip className="w-10 inline-block mb-4 text-red-600" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-black mb-4">
            Skills &amp; Technologies Used
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"></p>
        </div>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {skills.map((skill) => (
            <div key={skill.id} className="p-2 sm:w-1/2 w-full">
              <div className="bg-black rounded flex p-4 h-full items-center">
                <FaCheckCircle className="text-red-600 w-6 h-6 flex-shrink-0 mr-4" />
                <span className="title-font font-medium text-white">
                  {skill.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skillz;
